import { FONTS, FONT_FAMILY } from '../const/';

const DEMO = {
  name: 'Demo',

  spacing: 8,
  font: FONTS,
  fontFamily: FONT_FAMILY.default,

  background: {
    default: 'hsla(0,0%,100%,1)',
    primary: 'hsla(0,0%,98%,1)',
    secondary: 'hsla(0,0%,96%,1)',

    contrast: 'hsla(200,64%,10%,1)',

    brand: 'hsla(220,96%,56%,1)',

    hover: 'hsla(220,96%,96%,1)',

    error: 'hsla(352,88%,56%,1)',
    success: 'hsla(120,64%,72%,1)',
    warning: 'hsla(48,96%,64%,1)',
  },
  menu: {
    default: 'hsla(200,80%,8%,1)',
    collapsed: 'hsla(200,80%,8%,1)',
  },
  border: {
    default: 'hsla(0,0%,0%,.08)',
    contrast: 'hsla(0,0%,100%,.08)',
  },
  text: {
    default: 'hsla(232,8%,8%,1)',
    primary: 'hsla(220,96%,56%,1)',
    secondary: 'hsla(232,8%,56%,1)',
    disabled: 'hsla(232,8%,64%,1)',

    contrast: 'hsla(232,8%,98%,1)',

    error: 'hsla(352,88%,56%,1)',
    success: 'hsla(120,40%,56%,1)',
    warning: 'hsla(48,96%,48%,1)',

    dark: 'hsla(232,8%,24%,1)',
    light: 'hsla(232,8%,98%,1)',
  },
  button: {
    default: 'hsla(0,0%,88%,1)',
    primary: 'hsla(220,96%,56%,1)',
    secondary: 'hsla(352,88%,56%,1)',

    error: 'hsla(352,88%,56%,1)',
    success: 'hsla(120,64%,72%,1)',
    warning: 'hsla(48,96%,64%,1)',

    dark: 'hsla(232,8%,24%,1)',
    light: 'hsla(232,8%,98%,1)',
  },
  icon: {
    color: {
      default: 'hsla(232,8%,8%,1)',
      primary: 'hsla(220,96%,56%,1)',
      secondary: 'hsla(232,8%,56%,1)',
      disabled: 'hsla(232,8%,64%,1)',

      contrast: 'hsla(232,8%,98%,1)',

      error:'hsla(352,88%,56%,1)',
      success: 'hsla(120,40%,56%,1)',
      warning: 'hsla(48,96%,48%,1)',

      dark: 'hsla(232,8%,24%,1)',
      light: 'hsla(232,8%,98%,1)',
    },
  },
  link: {
    default: 'hsla(220,96%,56%,1)',
    hover: 'hsla(220,96%,40%,1)',
    active: 'hsla(220,96%,32%,1)',
    disabled: 'hsla(232,8%,64%,1)',
  },
};

const DEMO_DARK = {
  name: 'Demo Dark',

  spacing: 8,
  font: FONTS,
  fontFamily: FONT_FAMILY.default,

  background: {
    default: 'hsla(220,24%,16%,1)',
    primary: 'hsla(220,20%,20%,1)',
    secondary: 'hsla(220,40%,8%,1)',

    contrast: 'hsla(0,0%,100%,1)',

    brand: 'hsla(220,96%,56%,1)',

    hover: 'hsla(220,16%,36%,1)',

    error: 'hsla(352,88%,56%,1)',
    success: 'hsla(120,64%,72%,1)',
    warning: 'hsla(48,96%,64%,1)',
  },
  menu: {
    default: 'hsla(200,80%,8%,1)',
    collapsed: 'hsla(200,80%,8%,1)',
  },
  border: {
    default: 'hsla(0,0%,100%,.08)',
    contrast: 'hsla(0,0%,0%,.08)',
  },
  text: {
    default: 'hsla(232,8%,98%,1)',
    primary: 'hsla(220,96%,64%,1)',
    secondary: 'hsla(232,8%,56%,1)',
    disabled: 'hsla(232,8%,40%,1)',

    contrast: 'hsla(232,8%,24%,1)',

    error: 'hsla(352,88%,56%,1)',
    success: 'hsla(120,64%,72%,1)',
    warning: 'hsla(48,96%,64%,1)',

    dark: 'hsla(232,8%,24%,1)',
    light: 'hsla(232,8%,98%,1)',
  },
  button: {
    default: 'hsla(220,16%,24%,1)',
    primary: 'hsla(220,96%,56%,1)',
    secondary: 'hsla(352,88%,56%,1)',

    error: 'hsla(352,88%,56%,1)',
    success: 'hsla(120,64%,72%,1)',
    warning: 'hsla(48,96%,64%,1)',

    dark: 'hsla(232,8%,24%,1)',
    light: 'hsla(232,8%,98%,1)',
  },
  icon: {
    color: {
      default: 'hsla(232,8%,98%,1)',
      primary: 'hsla(220,96%,64%,1)',
      secondary: 'hsla(232,8%,56%,1)',
      disabled: 'hsla(232,8%,40%,1)',

      contrast: 'hsla(232,8%,24%,1)',

      error: 'hsla(352,88%,56%,1)',
      success: 'hsla(120,64%,72%,1)',
      warning: 'hsla(48,96%,64%,1)',

      dark: 'hsla(232,8%,24%,1)',
      light: 'hsla(232,8%,98%,1)',
    },
  },
  link: {
    default: 'hsla(220,96%,64%,1)',
    hover: 'hsla(220,96%,56%,1)',
    active: 'hsla(220,96%,48%,1)',
    disabled: 'hsla(232,8%,40%,1)',
  },
};

export { DEMO, DEMO_DARK };
