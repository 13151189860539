import React from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../../elements/Icon/';
import { FONTS, ICON_SIZE, TEXT_COLORS, TEXT_SIZE, LINE_SIZE, INDENT_SIZE } from '../../const/';

const StyledSectionHeader = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  padding: 8px 12px;
  align-items: center;
  ${({ borderHeader, theme }) => borderHeader && `border-bottom: 1px solid ${theme.border.default}`}
`;

const Container = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
  align-items: start;

  ${props => props.headerLink && `
    &:hover path {
      fill: ${props.theme.icon[props.hoverColor] ? props.theme.icon[props.hoverColor] : props.hoverColor};
    }

    &:hover {
      cusor: pointer;
    }
  `}
`;

const I = styled.i`
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  flex-shrink: 0;

  & > svg {
    margin: 4px;
  }
`;

const TitleContainer = styled.div`
  overflow: hidden;
  padding: 4px;
`;

const Subtitle = styled.div`
  font: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.text.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.h3`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font: ${({ theme }) => theme.font.m};
  color: ${({ theme }) => theme.text.default};
  margin: 0;
  font-weight: 600;

  & > ${ Subtitle } {
    padding-top: 4px;
  }
`;

const Buttons = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 -4px;
  padding-left: 8px;

  & > * {
    margin: 0 4px;
  }
`;

function SectionHeader(props) {
  const {
    title,
    subtitle,
    buttons,
    headerLink,
    onClick,
    icon,
    borderHeader,
    hoverColor,
    ...other
  } = props;

  return (
    <StyledSectionHeader borderHeader={borderHeader} {...other}>
      <Container as={headerLink && Link} to={headerLink && headerLink} headerLink={headerLink} hoverColor={hoverColor}>
        {icon && (
          <I><Icon icon={icon} size="x" /></I>
        )}
        {(title || subtitle) && (
          <TitleContainer>
            {typeof title === "string" ? (
              <Title>{title}</Title>
            ) : (
              title
            )}
            {subtitle && (
              <Subtitle>{subtitle}</Subtitle>
            )}
          </TitleContainer>
        )}
      </Container>
      {buttons && (
        <Buttons>{buttons}</Buttons>
      )}
    </StyledSectionHeader>
  );
}

SectionHeader.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  subtitle: PropTypes.string,
  headerLink: PropTypes.string,
  buttons: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  icon: PropTypes.string,
  borderHeader: PropTypes.bool,
  hoverColor: PropTypes.string,
}

SectionHeader.defaultProps = {
  title: "",
  subtitle: "",
  headerLink: "",
  buttons: undefined,
  icon: "",
  borderHeader: false,
  hoverColor: "primary",
}

export default SectionHeader;
