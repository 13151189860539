import React from 'react';
import { TrackbarArgs } from './types';
import { Line, VisualLine } from './views';


const Trackbar = (props: TrackbarArgs) => {
  return (
    <Line>
      <VisualLine {...props}>
        {props.children}
      </VisualLine>
    </Line>
  );
}


export default Trackbar;