import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BUTTON_SIZE, TEXT_SIZE, LINE_SIZE, OBJECT_TEXT_SIZE, OBJECT_LINE_SIZE } from '../../const/';
import { colorToHsla } from '../../assistants/';

const getFocusColorHelper = color => {
  const [h,s,l,a] = colorToHsla(color);
  return `hsla(${h},${s}%,${l}%,${.32})`
}

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: ${props => props.variant === 'column' ? 'column' : 'row'} nowrap;
  width: 100%;
  align-items: ${props => props.variant === 'column' ? 'stretch' : 'flex-start'};
  padding: 4px 0;
`;

const Label = styled.label`
  min-width: 120px;
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.variant === 'column' ? TEXT_SIZE.xs + 'px' : OBJECT_TEXT_SIZE[props.size] + 'px'};
  line-height: ${props => props.variant === 'column' ? LINE_SIZE.xs + 'px' : OBJECT_LINE_SIZE[props.size] + 'px'};
  color: ${props => props.theme.text.secondary};
  padding: ${props => props.variant === 'column' ? '4px' : (BUTTON_SIZE[props.size] - OBJECT_LINE_SIZE[props.size]) / 2 + 'px'} 0;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  margin-right: 12px;
`;

const ErrorText = styled.div`
  font: ${props => props.theme.font.xs};
  color: ${props => props.theme.text.error};
  padding-top: 4px;
`;

const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const StyledInput = styled.input`
  display: block;
  appearance: none;
  position: relative;
  box-sizing: border-box;
  border-radius: ${props => props.borderRadius};
  min-height: ${props => BUTTON_SIZE[props.size] + 'px'};
  border: 1px solid ${props => props.errorText ? props.theme.button.secondary : props.theme.button.default};
  font-family: ${props => props.theme.fontFamily};
  padding:  ${props => props.type === 'textarea' ? (BUTTON_SIZE[props.size] - OBJECT_LINE_SIZE[props.size]) / 2 - 1 : '0'}px ${props => props.theme.spacing}px;
  font-size: ${props => OBJECT_TEXT_SIZE[props.size]}px;
  line-height: ${props => OBJECT_LINE_SIZE[props.size]}px;
  color: ${props => props.theme.text.default};
  border-image: initial;
  outline: none;
  background: transparent;
  resize: none;
  width: 100%;
  z-index: ${props => props.errorText ? 10 : 1};

  ${props => props.type === 'textarea' && 'height: ' + (props.lines * OBJECT_LINE_SIZE[props.size] + (BUTTON_SIZE[props.size] - OBJECT_LINE_SIZE[props.size]) / 2 - 1) + 'px'};

  &:placeholder {
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => OBJECT_TEXT_SIZE[props.size] + 'px'};
    line-height: ${props => OBJECT_LINE_SIZE[props.size] + 'px'};
    color: ${props => props.theme.text.secondary};
  }

  &:-webkit-autofill {
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => OBJECT_TEXT_SIZE[props.size] + 'px'};
    line-height: ${props => OBJECT_LINE_SIZE[props.size] + 'px'};
    color: ${props => props.theme.text.default};
  }

  &:focus {
    border-color: ${props => props.theme.button.primary};
    box-shadow: 0 0 0 2px ${props => getFocusColorHelper(props.theme.button.primary)};
    z-index: 10;
  }
`;

function Input(props) {
  const {
    label,
    afterLabelTooltip,
    name,
    type,
    value,
    onChange,
    errorText,
    afterErrorTooltip,
    placeholder,
    readOnly,
    inputRef,
    maxlength,
    size,
    variant,
    tabIndex,
    lines,
    borderRadius,
    removeSpaces,
    autoFocus,

    handlerBlur,
    isValid,

    theme,

    innerRef,
    ...other
  } = props;

  (maxlength === '30' || maxlength === 30) && console.warn('Длина поля дефолтная!');

  const onChangeCallback = e => {
    let result = e.target.value;
    onChange(result);
  };

  return (
    <InputContainer variant={theme ? theme: variant} {...other}>
      {label && <Label htmlFor={name} size={size} variant={theme ? theme: variant}>{label}{afterLabelTooltip}</Label>}
      <Container>
        <StyledInput
          as={type === 'textarea' && 'textarea'}
          tabIndex={tabIndex}
          errorText={errorText}
          size={size}
          ref={inputRef}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          maxLength={maxlength}
          lines={type === 'textarea' && lines}
          onChange={onChangeCallback}
          onBlur={handlerBlur}
          readOnly={readOnly && "readOnly"}
          borderRadius={borderRadius}
          autoFocus={autoFocus}
          isValid={isValid}
          ref={innerRef}
          {...other}
        />
        {errorText && (
          <ErrorText>{errorText}{afterErrorTooltip}</ErrorText>
        )}
      </Container>
    </InputContainer>
  );
}

Input.propTypes = {

};

Input.defaultProps = {
  label: '',
  tabIndex: '',
  name: '',
  errorText: '',
  inputRef: undefined,
  maxlength: '30',
  placeholder: '',
  readonly: false,
  type: 'text',
  removeSpaces: true,
  size: 's',
  variant: 'line',
  lines: '4',
  borderRadius: '4px',
  autoFocus: false,
};

export default Input;
