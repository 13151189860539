import { FONTS, FONT_FAMILY } from './fonts';

export const FACEMACHINE_THEME = {
  spacing: 8,
  background: {
    default:    'hsla(0,0%,100%,1)',  // Цвет фона по умолчанию
    primary:    'hsla(216,96%,64%,1)',   //Фирменный цвет фона
    secondary:  'hsla(0,0%,96%,1)',
    tertiary:  'hsla(0,0%,98%,1)',
    contrast:  'hsla(0,0%,8%,1)',
    error: '',
    lightError: 'hsla(0,100%,98%,1)',
  },
  menu: {
    default: 'hsla(216,40%,8%,1)',   // Цвет развёрнутого меню
    collapsed: 'hsla(216,40%,8%,1)', // Цвет свёрнутого меню
  },
  border: {
    default: 'hsla(0,0%,0%,.08)',     // Цвет разделителя
    contrast: 'hsla(0,0%,100%,.08)',  // Цвет разделителя на тёмном фоне
  },
  text: {
    primary:  "hsla(216,96%,56%,1)", // Стандартное положительно действие и основной цвет

    default: 'hsla(0,0%,12%,1)', // Цвет по умолчанию
    secondary: 'hsla(0,0%,48%,1)', //
    tertiary: 'hsla(0,0%,64%,1)', //
    defaultContrast: 'hsla(0,0%,100%,1)', //
    secondaryContrast: 'hsla(0,0%,64%,1)',
    tertiaryContrast: 'hsla(0,0%,72%,1)',
    error: 'hsla(360,100%,48%,1)',
  },
  button: {
    primary:    'hsla(216,96%,64%,1)', // Стандартное положительно действие и основной цвет
    secondary:  'hsla(360,96%,56%,1)', // Дополнительный цвет

    default:    'hsla(0,0%,88%,1)', // Цвет по умолчанию
    contrast:   'hsla(0,0%,100%,1)', // Цвет по умолчанию на тёмном фоне

    error: 'hsla(360,96%,56%,1)', // Цвет негативного действия
  },
  icon: {
    color: {
      primary: 'hsla(216,96%,64%,1)',
      secondary: 'hsla(360,96%,56%,1)',
      default: 'hsla(0,0%,12%,1)',
      contrast: 'hsla(0,0%,100%,1)',
    },
  },
  link: {
    default:  "hsla(216,96%,56%,1)",
    hover:    "hsla(360,88%,48%,1)",
    active:   "hsla(360,88%,48%,1)",
    disabled: "hsla(0,0%,64%,1)",
  },
  font: FONTS,
  fontFamily: FONT_FAMILY.default,
};

export const LICENSESERVER_THEME = {
  spacing: 8,
  background: {
    default:    'hsla(0,0%,100%,1)',  // Цвет фона по умолчанию
    primary:    'hsla(120,48%,48%,1)',   // Фирменный цыет фона
    secondary:  'hsla(0,0%,96%,1)',   // Дополнительный фоновый цвет для разграничения объектов, находящихся на одной плоскости
    tertiary:  'hsla(0,0%,98%,1)',
    contrast:  'hsla(0,0%,8%,1)',
    error: '',
    lightError: 'hsla(0,100%,98%,1)',
  },
  menu: {
    default: 'hsla(0,0%,100%,1)',     // Цвет развёрнутого меню
    collapsed: 'hsla(120,48%,56%,1)', // Цвет свёрнутого меню
  },
  border: {
    default: 'hsla(0,0%,0%,.08)',     // Цвет разделителя
    contrast: 'hsla(0,0%,100%,.08)',  // Инверсный цвет разделителя
  },
  text: {
    default: 'hsla(0,0%,12%,1)',
    primary:  'hsla(120,40%,48%,1)',
    secondary: 'hsla(0,0%,48%,1)',
    tertiary: 'hsla(0,0%,64%,1)',
    defaultContrast: 'hsla(0,0%,100%,1)',
    secondaryContrast: 'hsla(0,0%,64%,1)',
    tertiaryContrast: 'hsla(0,0%,72%,1)',
    error: 'hsla(360,100%,48%,1)',
  },
  button: {
    primary: 'hsla(120,40%,56%,1)',
    secondary: 'hsla(360,96%,56%,1)',
    default: 'hsla(0,0%,88%,1)',
    contrast:   'hsla(0,0%,100%,1)',
  },
  icon: {
    color: {
      primary: 'hsla(120,40%,48%,1)',
      secondary: 'hsla(360,96%,56%,1)',
      default: 'hsla(0,0%,12%,1)',
      contrast:   'hsla(0,0%,100%,1)',
    },
  },
  link: {
    default:  'hsla(120,40%,48%,1)',
    hover:    'hsla(120,40%,40%,1)',
    active:   'hsla(120,40%,32%,1)',
    disabled: 'hsla(0,0%,64%,1)',
  },
  font: FONTS,
  fontFamily: FONT_FAMILY.default,
};

export const MAIN_THEME = {
  spacing: 8,
  background: {
    default:    'hsla(0,0%,100%,1)',
    primary:    'hsla(360,96%,56%,1)',
    secondary:  'hsla(0,0%,96%,1)',
    tertiary: 'hsla(0,0%,98%,1)',
    contrast:  'hsla(0,0%,8%,1)',
    error: '',
    lightError: 'hsla(0,100%,98%,1)',
  },
  menu: {
    default: 'hsla(0,0%,100%,1)',
    collapsed: 'hsla(0,0%,100%,1)',
  },
  border: {
    default: 'hsla(0,0%,0%,.08)',
    contrast: 'hsla(0,0%,100%,.08)',
  },
  text: {
    default: 'hsla(0,0%,12%,1)',
    primary:  "hsla(360,72%,56%,1)",
    secondary: 'hsla(0,0%,48%,1)',
    tertiary: 'hsla(0,0%,64%,1)',
    defaultContrast: 'hsla(0,0%,100%,1)',
    secondaryContrast: 'hsla(0,0%,64%,1)',
    tertiaryContrast: 'hsla(0,0%,72%,1)',
    error: 'hsla(360,100%,48%,1)',
  },
  button: {
    primary: 'hsla(360,96%,56%,1)',
    secondary: 'hsla(360,96%,56%,1)',
    default: 'hsla(0,0%,88%,1)',
    contrast:   'hsla(0,0%,100%,1)',
  },
  icon: {
    color: {
      primary: 'hsla(360,96%,56%,1)',
      secondary: 'hsla(360,96%,56%,1)',
      default: 'hsla(0,0%,12%,1)',
      contrast:   'hsla(0,0%,100%,1)',
    },
  },
  link: {
    default:  "hsla(360,72%,56%,1)",
    hover:    "hsla(360,72%,48%,1)",
    active:   "hsla(360,88%,40%,1)",
    disabled: "hsla(0,0%,64%,1)",
  },
  font: FONTS,
  fontFamily: FONT_FAMILY.default,
};
