import { ReactElement, ReactNode } from "react";

interface BaseDirections {
  vertical: vertical,
  horizontal: horizontal,
}

export const VERTICALS_TYPES = ['top', 'center', 'bottom'] as const;
export const HORIZONTALS_TYPES = ['left', 'center', 'right'] as const;

export type vertical = typeof VERTICALS_TYPES[number];
export type horizontal = typeof HORIZONTALS_TYPES[number];
export interface IAnchor extends BaseDirections {
  isDisplayed?: boolean;
}

export interface ITransform extends BaseDirections {

}

export interface IPopover extends IContentStyled {
  children: ReactElement,
  content: ReactNode,
  anchor?: IAnchor,
  spacing?: number,
  transform?: ITransform,
  isDisplayed?: boolean;
}

export interface IContentStyled {
  minWidth: number,
  maxWidth: number,
  arrow?: {
    isDisplayed?: boolean,
    spacing?: number,
    size?: number
  },
}