import React from 'react';
import TrackbarPointValueLabel from '../Slider/TrackbarPointValueLabel';
import { PlaybackLabelArgs } from './types';
import { Time } from './views';


function ProgressLabel({
  current, duration, pointedTime }: PlaybackLabelArgs) {

  function getISOTimeString(seconds: number) {
    const dt = new Date(seconds * 1000);
    return dt.toISOString().substr(11, 8);
  }

  const isoCurrentTime = getISOTimeString(current);
  const isoDuration = getISOTimeString(duration);

  const { value, left } = pointedTime || {
    value: undefined,
    left: undefined
  }
  const isoPointedTime = value > 0 && getISOTimeString(value);


  return (
    <>
      <Time>
        {isoDuration} · {isoCurrentTime}
      </Time>
      {isoPointedTime &&
        <TrackbarPointValueLabel
          left={left}
          value={isoPointedTime}
        />}
    </>
  )
}


export default ProgressLabel;
