import React from 'react';
import PropTypes from "prop-types";

function NumberAbbreviation(props) {
  const {
    number
  } = props;
  const log = Math.floor(Math.log10(number));
  switch(log) {
    case (-Infinity):
    case (0):
    case (1):
    case (2):
      return number;
    case (3):
    case (4):
    case (5):
      return Number((number/1000).toPrecision(3)) + "K";
    case (6):
    case (7):
    case (8):
      return Number((number/1000000).toPrecision(3)) + "M";
    case (9):
    case (10):
    case (11):
      return Number((number/1000000000).toPrecision(3)) + "B";
    default:
      return number;
  }
}

NumberAbbreviation.propTypes = {
  number: PropTypes.number
}

NumberAbbreviation.defaultProps = {
  number: undefined
}

export default NumberAbbreviation;
