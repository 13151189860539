import { useCallback, useEffect, useState } from "react";

const useToggleDisplay = (defaultDisplayed: boolean) => {
  const [isDisplayed, setDisplayed] = useState(defaultDisplayed);

  useEffect(() => {
    setDisplayed(defaultDisplayed);
  }, [defaultDisplayed]);

  const toggleDisplayed = useCallback(() => setDisplayed(prevState => !prevState), []);

  const handleDisplayed = useCallback(() => setDisplayed(true), []);

  const handleHide = useCallback(() => setDisplayed(false), []);

  return { isDisplayed, onToggleDisplayed: toggleDisplayed, onDisplay: handleDisplayed, onHide: handleHide };
};

export default useToggleDisplay;