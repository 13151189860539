import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../';

class CopyButton extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      isCopied: false
    }
  }

  handlerCopied = (e) => {
    let parent = e.target.parentElement.parentElement;
    this.setState({
      isCopied: true,
    });
    this.props.onClick(e);
    e.target.addEventListener('mouseleave', this.cleareCopy)
  }

  cleareCopy = (e) => {
    this.setState({
      isCopied: false
    });
    e.target.removeEventListener('mouseleave', this.cleareCopy);
  }

  render() {
    const {
      href,
      onClick,
      disabled,
      loading,
      status,
      size,
      text,
      elastic,
      round,
      iconLeft,
      iconRight,
      variant,
      color,
      ...other
    } = this.props;
    return(
      <Button disabled={disabled} status={status} iconLeft={iconLeft} iconRight={iconRight} href={href} text={!this.state.isCopied ? 'Copy' : 'Copied'} elastic={elastic} round={round} onClick={this.handlerCopied} size={size} color={color} variant={variant} loading={loading} {...other} />
    );
  }
}

export default CopyButton;
