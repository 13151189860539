import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';

const withBox = Component => styled(Component)`
  ${props => props.m && `margin: ${props.theme.spacing * props.m}px;`}
  ${props => props.p && `padding: ${props.theme.spacing * props.p}px;`}

  ${props => (props.mx || props.my) && `margin: ${props.my ? props.theme.spacing * props.my : 0}px ${props.mx ? props.theme.spacing * props.mx : 0}px;`}

  ${props => props.ml && `margin-left: ${props.theme.spacing * props.ml}px;`}
  ${props => props.mr && `margin-right: ${props.theme.spacing * props.mr}px;`}
  ${props => props.mt && `margin-top: ${props.theme.spacing * props.mt}px;`}
  ${props => props.mb && `margin-bottom: ${props.theme.spacing * props.mb}px;`}

  ${props => (props.px || props.py) && `padding: ${props.py ? props.theme.spacing * props.py : 0}px ${props.px ? props.theme.spacing * props.px : 0}px;`}

  ${props => props.pl && `padding-left: ${props.theme.spacing * props.pl}px;`}
  ${props => props.pr && `padding-right: ${props.theme.spacing * props.pr}px;`}
  ${props => props.pt && `padding-top: ${props.theme.spacing * props.pt}px;`}
  ${props => props.pb && `padding-bottom: ${props.theme.spacing * props.pb}px;`}
`;

const Container = withBox(({ children, className }) =>
  React.cloneElement(children, { className })
);

const StyledBox = styled.div`
  ${props => props.m && `margin: ${props.theme.spacing * props.m}px;`}
  ${props => props.p && `padding: ${props.theme.spacing * props.p}px;`}

  ${props => (props.mx || props.my) && `margin: ${props.my ? props.theme.spacing * props.my : 0}px ${props.mx ? props.theme.spacing * props.mx : 0}px;`}

  ${props => props.ml && `margin-left: ${props.theme.spacing * props.ml}px;`}
  ${props => props.mr && `margin-right: ${props.theme.spacing * props.mr}px;`}
  ${props => props.mt && `margin-top: ${props.theme.spacing * props.mt}px;`}
  ${props => props.mb && `margin-bottom: ${props.theme.spacing * props.mb}px;`}

  ${props => (props.px || props.py) && `padding: ${props.py ? props.theme.spacing * props.py : 0}px ${props.px ? props.theme.spacing * props.px : 0}px;`}

  ${props => props.pl && `padding-left: ${props.theme.spacing * props.pl}px;`}
  ${props => props.pr && `padding-right: ${props.theme.spacing * props.pr}px;`}
  ${props => props.pt && `padding-top: ${props.theme.spacing * props.pt}px;`}
  ${props => props.pb && `padding-bottom: ${props.theme.spacing * props.pb}px;`}
`;

const Box = React.forwardRef((props, ref) => {
  const {
    children,
    pass,
    m,mx,my,ml,mr,mt,mb,
    p,px,py,pl,pr,pt,pb,
    ...other
  } = props;

  if (pass) {
    return <Container children={children} m={m} mx={mx} my={my} ml={ml} mr={mr} mt={mt} mb={mb} p={p} px={px} py={py} pl={pl} pr={pr} pt={pt} pb={pb} {...other} />;
  }

  return (
    <StyledBox ref={ref} m={m} mx={mx} my={my} ml={ml} mr={mr} mt={mt} mb={mb} p={p} px={px} py={py} pl={pl} pr={pr} pt={pt} pb={pb} {...other}>
      {children}
    </StyledBox>
  );
});

Box.propTypes = {

};

Box.defaultProps = {
  children: '',
  pass: '',
  m: '',
  mx: '',
  my: '',
  ml: '',
  mr: '',
  mt: '',
  mb: '',
  p: '',
  px: '',
  py: '',
  pl: '',
  pr: '',
  pt: '',
  pb: '',
};

export default Box
