import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import Icon from '../Icon/';
import Preloader from '../Preloader/';
import { SPACING_SIZE, BUTTON_SIZE, OBJECT_ICON_SIZE, TEXT_SIZE, OBJECT_TEXT_SIZE, OBJECT_LINE_SIZE } from '../../const/';
import getColors from './getColors';

const Text = styled.span`
  flex: 1 1 0;
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => OBJECT_TEXT_SIZE[props.size]}px;
  line-height: ${props => OBJECT_LINE_SIZE[props.size]}px;
  white-space: nowrap;
  padding:  0px ${props => props.theme.spacing * SPACING_SIZE[props.size]}px
            0px ${props => props.theme.spacing * SPACING_SIZE[props.size]}px;
  text-align: ${props => props.textAlign};
  z-index: -1;
  color: ${props => props.textColor[props.state]};
  ${props => props.isLoading && 'opacity: 0'};
`;

const ButtonIcon = styled(Icon)`
  z-index: -1;
  ${props => props.isLoading && 'opacity: 0'};
`;

const ButtonPreloader = styled(Preloader)`
  position: absolute;
  left: 0;
  right: 0;
`;

const StyledButton = styled.button`
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  flex-shrink: 0;

  ${props => (!props.text || (!props.text && props.round)) && `
    justify-content: center;
  `}

  border: 1px solid;
  border-color: ${props => props.borderColor.default};

  padding: 0 ${props => (!props.text || (!props.text && props.round)) ? 0 : props.theme.spacing * SPACING_SIZE[props.size] * 2}px;

  text-decoration: none;

  width: ${props => props.elastic ? '100%' : (props.width ? props.width : 'auto')};
  min-height: ${props => BUTTON_SIZE[props.size] + 'px'};
  min-width: ${props => BUTTON_SIZE[props.size] + 'px'};
  border-radius: ${props => props.round ? '50%' : props.borderRadius};

  background: ${props => props.color.default};

  pointer-events: ${props => props.isLoading ? 'none' : 'auto'};
  z-index: ${props => props.active ? '2' : '1'};

  &:hover {
    cursor: pointer;
    border-color: ${props => props.borderColor.hover};
    background: ${props => props.color.hover};
    z-index: 2;
  }

  &[disabled] {
    pointer-events: none;
    border-color: ${props => props.borderColor.disabled};
    background: ${props => props.color.disabled};
  }

  &:focus {
    outline: none;
    ${props => props.variant === 'outlined' && `border-color: ${props.borderColor.hover};`}
    background: ${props => props.color.focus};
    box-shadow: 0 0 0 2px ${props => props.borderColor.focus};
    z-index: 2;
  }

  &:focus ${Text} {
    color: ${props => props.textColor.focus};
  }

  &:focus path {
    fill: ${props => props.iconColor.focus};
  }

  &:active {
    outline: none;
    transform: translateY(1px);
    z-index: 2;
  }

  & > ${Icon} {
    vertical-align: middle;
  }
`;

function Button(props) {
  const {
    href,
    onClick,
    tabIndex,
    disabled,
    loading,

    iconLeft,
    iconRight,
    children,

    size,
    width,
    elastic,
    round,
    borderRadius,
    textAlign,
    active,

    variant,

    color,
    hoverColor,
    iconColor,

    /* Old props */
    theme,
    text,

    innerRef,
    ...other
  } = props;

  const themeContext = useContext(ThemeContext);

  const btnVariant = theme ? theme === 'outline' ? 'outlined' : theme : variant;

  const btnStyles = getColors(color, hoverColor, iconColor, btnVariant, themeContext);

  const [state, setState] = useState(disabled ? 'disabled' : 'default');

  useEffect(() => {
    setState(disabled ? 'disabled' : 'default');
  }, [disabled])

  return (
    <StyledButton
      ref={innerRef}
      onMouseEnter={() => disabled ? undefined : setState('hover')}
      onMouseLeave={() => disabled ? undefined : setState('default')}
      onMouseDown={() => disabled ? undefined : setState('active')}
      onMouseUp={() => disabled ? undefined : setState('hover')}

      href={href ? href : ''}
      onClick={onClick ? onClick : ''}
      tabIndex={tabIndex}
      disabled={disabled}
      isLoading={loading}

      iconLeft={iconLeft}
      iconRight={iconRight}

      size={size}
      width={width}
      elastic={elastic}
      round={round}
      borderRadius={borderRadius}
      active={active}

      variant={btnVariant}

      color={btnStyles.button}
      borderColor={btnStyles.border}
      textColor={btnStyles.text}
      iconColor={btnStyles.icon}
      state={state}
      text={text}

      hoverColor={hoverColor}

      {...other}
    >
      {loading && (
        <ButtonPreloader
          size={size}
          color={btnStyles.text[state]}
        />
      )}
      {iconLeft && (
        <ButtonIcon
          isLoading={loading}
          icon={iconLeft}
          size={OBJECT_ICON_SIZE[size]}
          color={btnStyles.icon[state]}
        />
      )}
      {text && (
        <Text
          size={size}
          disabled={disabled}
          textColor={btnStyles.text}
          state={state}
          isLoading={loading}
          textAlign={textAlign}
          iconRight={iconRight}
          iconLeft={iconLeft}
        >
          {text}
        </Text>
      )}
      {iconRight && (
        <ButtonIcon
          isLoading={loading}
          icon={iconRight}
          size={OBJECT_ICON_SIZE[size]}
          color={btnStyles.icon[state]}
        />
      )}
    </StyledButton>
  );
}

Button.propTypes = {

};

Button.defaultProps = {
  href: '',
  onClick: (e) => { },
  tabIndex: '',
  disabled: false,
  loading: false,

  iconLeft: '',
  iconRight: '',

  size: 's',
  width: '',
  elastic: false,
  round: false,
  borderRadius: '4px',
  textAlign: 'center',
  active: false,

  variant: 'outlined',

  color: 'default',
  hoverColor: '',
  iconColor: '',

  theme: '',
  text: ''
};

export default Button;
