import React, { useContext, useMemo } from 'react';
import { GRID_ALIGN_ITEMS_TYPES, GRID_DIRECTIONS_TYPES, GRID_JUSTIFY_TYPES, IGrid } from './types';
import { GridView } from './components';
import { GridContext, GridContextProvider } from './GridContext';

function Grid({
  wrap = true,
  children,
  spacing = 0,
  item = false,
  container = false,
  direction = GRID_DIRECTIONS_TYPES.row,
  justify = GRID_JUSTIFY_TYPES['flex-start'],
  alignItems = GRID_ALIGN_ITEMS_TYPES['flex-start'],
  xl, lg, md, sm, xs,
}: IGrid): JSX.Element {
  const contextValue = useMemo(() => ({ itemSpacing: spacing }), [spacing]);
  const { itemSpacing } = useContext(GridContext);

  if (!children) return <div></div>;

  return (
    <GridView
      item={item}
      wrap={wrap}
      spacing={spacing}
      itemSpacing={itemSpacing}
      justify={justify}
      direction={direction}
      container={container}
      alignItems={alignItems}
      xl={xl} lg={lg} md={md} sm={sm} xs={xs}
    >
      <GridContextProvider value={contextValue}>
        {children}
      </GridContextProvider>
    </GridView>
  )
}

export default Grid;
