import React, { useContext, useEffect, useRef } from "react";
import { ITab } from "./types";
import { TabsContext } from "./TabsContext";

import { TabButton, TabView } from "./components";
import { Grid } from "..";

const Tab = <T extends (string | number),>({ label = "", icon, value }: ITab<T>) => {
  const { activeValue, tabSize, onChange, setActiveTab } = useContext(TabsContext);
  const tabRef = useRef<HTMLDivElement>(null);
  const isActive = activeValue === value;

  useEffect(() => {
    if (isActive && tabRef.current && setActiveTab)
      setActiveTab(tabRef.current)
  }, [isActive, tabRef.current]);

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onChange && onChange(e, value);
  }

  return (
    <TabView ref={tabRef} >
      <Grid item>
        <TabButton isActive={isActive} onClick={handleClick} elastic active={isActive} text={label} variant="clear" iconLeft={icon} iconColor={isActive ? 'primary' : 'default'} size={tabSize} />
      </Grid>
    </TabView>
  );
}

export default Tab;
