import React, { useEffect, useState } from "react";
import { Utils } from "../../utils";
import { default as BaseButton } from '../Button';

import { SliderBarColor, SliderBgColor } from '../Slider/types';
import { TooltipArgs, PlayerControlPanelArgs } from "./types";
import * as SliderWrappers from "../Slider/views";
import * as ThisWrappers from "./views";

import Trackbar from "../Slider/Trackbar";
import Slider from "../Slider/Slider";
import ProgressLabel from "./ProgressLabel";



function PlayerControlPanel({
  playbackTime, borderRadius = '4px',
  min = 0, max, isPlayRequested = false,
  onClick, ...rest }: PlayerControlPanelArgs) {


  const [pointedTime, setPointedTime] = useState({} as TooltipArgs);
  const [sliderXPos, setSliderXPos] = useState(0);
  const hidePointedTimeLabel = () => setPointedTime({} as TooltipArgs);

  const [value, setValue] = useState({ ...playbackTime });
  const calcPercentage = Utils.calcPercentageInRange;


  const labelPointedTime = (e: React.PointerEvent) => {
    const { clientX, target } = e;
    const slider = target as HTMLElement;

    if (!sliderXPos) {
      const { x } = slider.getBoundingClientRect();
      setSliderXPos(x);
    }
    const { clientWidth } = slider;
    const pointedTime = (clientX - sliderXPos) / clientWidth * max;
    setPointedTime({
      left: (clientX - sliderXPos),
      value: pointedTime
    });
  };


  useEffect(
    () => {
      const limit = calcPercentage(playbackTime.limit, min, max);
      setValue({
        ...playbackTime,
        limit
      });
    },
    [playbackTime]
  );


  return (
    <ThisWrappers.Background
      borderRadius={borderRadius}
    >
      <ThisWrappers.ContentsPanel>
        <SliderWrappers.StyledSlider>
          <ThisWrappers.Button>
            <BaseButton
              onClick={onClick}
              variant='contained'
              iconLeft={isPlayRequested ? 'pause' : 'play'}
            />
          </ThisWrappers.Button>

          <ThisWrappers.TrackbarWrapper>
            <Trackbar
              value={value.limit || 0}
              bgColor={SliderBgColor.main}
              barColor={SliderBarColor.secondary}
            >
              <Slider
                value={value.current || 0}
                bgColor={SliderBgColor.none}
                min={min}
                max={max}
                onPointerMove={labelPointedTime}
                onPointerLeave={hidePointedTimeLabel}
                {...rest}
              />
            </Trackbar>

            <ProgressLabel
              pointedTime={pointedTime}
              current={playbackTime.current}
              duration={max}
            />
          </ThisWrappers.TrackbarWrapper>
        </SliderWrappers.StyledSlider>
      </ThisWrappers.ContentsPanel>
    </ThisWrappers.Background>
  );
}


export default PlayerControlPanel;