import React, { cloneElement, useEffect, useRef, useState } from 'react';
import { useOutsideClick, useToggleDisplay } from '../../hooks';
import { MAIN } from '../../themes';

import * as S from './components';
import { IPopover, VERTICALS_TYPES, HORIZONTALS_TYPES } from './types';
import { getContentPosition } from './utils';

const Popover = ({
  children,
  content,
  minWidth = 200,
  maxWidth = 264,
  spacing = 0,
  anchor = {
    vertical: VERTICALS_TYPES[2],
    horizontal: HORIZONTALS_TYPES[1],
    isDisplayed: false,
  },
  transform = {
    vertical: VERTICALS_TYPES[0],
    horizontal: HORIZONTALS_TYPES[0]
  },
  // arrow = {
  //   isDisplayed: false,
  //   spacing: 0,
  //   size: 1,
  // },
  isDisplayed = false
}: IPopover): JSX.Element => {
  const { isDisplayed: isPopoverDisplayed, onToggleDisplayed, onHide } = useToggleDisplay(isDisplayed);
  const anchorRef = useRef<HTMLElement>(null);
  const wrapperRef = useRef(null);

  useOutsideClick({ ref: wrapperRef, onClose: onHide });
  const [contentPositions, setContentPositions] = useState({});

  useEffect(() => {
    if (anchorRef.current) {
      const anchorDimensions = {
        left: anchorRef.current.offsetLeft,
        top: anchorRef.current.offsetTop,
        width: anchorRef.current.offsetWidth
      };

      setContentPositions(getContentPosition({ anchorDimensions, transform, spacing: spacing * MAIN.spacing }));
    }
  }, [spacing, anchor.horizontal, anchor.vertical, transform.horizontal, transform.vertical]);

  return (
    <S.Wrapper ref={wrapperRef}>
      {cloneElement(children, {
        onClick: onToggleDisplayed
      })}
      <S.Anchor {...anchor} ref={anchorRef} />
      {isPopoverDisplayed && (
        <S.ContentWrapper style={contentPositions} minWidth={minWidth} maxWidth={maxWidth}>
          {content}
        </S.ContentWrapper>
      )}
    </S.Wrapper>
  )
}

export default Popover;
