import React, { useState, useMemo, PropsWithChildren } from "react";

import { GRID_ALIGN_ITEMS_TYPES, GRID_DIRECTIONS_TYPES, GRID_JUSTIFY_TYPES } from "../GridLayout/types";
import { DIVIDER_ORIENTATIONS } from "../../elements/Divider/types";
import { ITabs, TABS_LINE_POSITIONS } from "./types";

import Grid from "../GridLayout";
import { TabsContextProvider } from "./TabsContext";
import { Line, Wrapper } from "./components";

const Tabs = <T extends string | number,>({
  value,
  lineSize = 1,
  linePosition = TABS_LINE_POSITIONS.right,
  tabSize = 'm',
  elastic,
  onChange,
  children,
  spacing = 0,
  direction = GRID_DIRECTIONS_TYPES.row,
  justify = GRID_JUSTIFY_TYPES["flex-start"],
  alignItems = GRID_ALIGN_ITEMS_TYPES["flex-start"],
}: PropsWithChildren<ITabs<T>>): JSX.Element => {
  const [activeTab, setActiveTab] = useState<HTMLElement>();
  const orientation = direction.includes(GRID_DIRECTIONS_TYPES.column) ? 'Y' : 'X';

  const lineStyle = useMemo(() => {
    if (!activeTab) return {};

    const tabBounding = activeTab.getBoundingClientRect();

    return {
      dimension: orientation === DIVIDER_ORIENTATIONS.X ? tabBounding.width : tabBounding.height,
      offset: orientation === DIVIDER_ORIENTATIONS.X ? activeTab.offsetLeft : activeTab.offsetTop,
    };
  }, [activeTab, orientation, tabSize]);

  const contextValues = useMemo(() => ({ activeValue: value, tabSize, onChange, setActiveTab }), [value, tabSize]);

  return (
    <Wrapper>
      <Grid container wrap={false} justify={elastic ? GRID_JUSTIFY_TYPES["space-between"] : justify} alignItems={alignItems} spacing={spacing} direction={direction}>
        {/* @ts-ignore */}
        <TabsContextProvider value={contextValues}>
          {children}
        </TabsContextProvider>
      </Grid>
      <Line direction={orientation} orientation={orientation} size={lineSize} position={linePosition} {...lineStyle} />
    </Wrapper>
  );
}

export default Tabs;
